<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学习培训</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="warp">
      <div class="img-list">
        <el-row :gutter="40">
          <el-col v-for="(image, index) in list" :key="'image' + index" :span="12" class="row-spacing">
            <el-image :src="imgs[index].url" style="width: 100%; height: auto;" @click="handleClick(image, index)"
              class="image-shadow" />
          </el-col>
        </el-row>
      </div>
    </div>

  </div>
</template>

<script>
import { getQCVideoMains } from '@/api'

export default {
  name: "XXPX",
  data() {
    return {
      list:[],
      imgs: [
        {
          url: require('@/assets/zczb/10.jpg')
        },
        {
          url: require('@/assets/zczb/8.jpg')
        },
        {
          url: require('@/assets/zczb/9.jpg')
        },
        
      ],
    }
  },
  methods: {
    async getQCVideoMainsAsync() {
      this.isLoading = true
      const r = await getQCVideoMains()
      this.isLoading = false
      if (r.code == 1) {
        this.list = r.data
      } else {
        const msg = r.error.Message;
        this.$message.error(msg);
      }
    },
    handleClick(row, idx) {
      if (idx == 0) {
        this.$router.push('/ZCZB')
        return
      }
      this.$router.push({
        path: `/ZCZBDetail/${row.Id}/${row.Name}`
      })
    }
  },
  created() {
    this.getQCVideoMainsAsync()
  }
}
</script>

<style scoped>
.warp {
  margin: 40px;
  display: flex;
  justify-content: center;
}

.img-list {
  width: 80%;
}

.row-spacing {
  margin-bottom: 40px;
  cursor: pointer;
}

.shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.image-shadow {
  transition: box-shadow 0.3s ease;
  /* 添加过渡效果 */
}

.image-shadow:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  /* 鼠标悬停时的阴影效果 */
}
</style>